import React, { useContext, useState, useEffect } from "react";
import { GET_FAVORITE_ITEMS, GET_PRODUCTS } from "../../querys";
import { useMutation, useQuery } from "@apollo/client";
import { typeUserManagment } from "../../services/querysData";
import { AuthUserContext } from "../../context/AuthUser";
import HeartLike from "./Style/HeartLike";
import CardProduct from "./CardProduct";
import { ButtonTL } from "../styled/Button";
import SmallLoader from "../small-loader";
import { Container } from "../styled/ProductsStyled";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

import {
  ADD_FAVORITE,
  CREATE_SHIPPING_CART,
  GET_SHIPPING_CART,
  UPDATE_FAVORITE,
  UPDATE_SHIPPING_CART,
} from "../../mutations";
import { toast } from "react-toastify";
import Slider from "react-slick";
import Loader from "../Loader";

const ProductCardType = ({ title, search }) => {
  const { authUser } = useContext(AuthUserContext);

  const { data: dataProducts, loading } = useQuery(GET_PRODUCTS, {
    variables: {
      vip: authUser?.typeCustomer && typeUserManagment(authUser.typeCustomer),
      typeSort: "id:DESC",
      search: search,
    },
  });
  const [favoriteItem, setFavoriteItem] = useState({});
  const { data: favoriteList } = useQuery(GET_FAVORITE_ITEMS, {
    variables: {
      id: authUser?.user?.id,
    },
    onCompleted: (data) => {
      setFavoriteItem(data);
    },
  });

  const [addFavorite] = useMutation(ADD_FAVORITE, {
    onCompleted: () => {
      toast.success("Added to favorites successfully");
    },
    onError: () => {
      toast.error("Failed to add favorite, there was a problem on the server");
    },
  });
  const [updateFavorite] = useMutation(UPDATE_FAVORITE, {
    onCompleted: () => {
      // toast.success("Your favorites were updated");
    },
    onError: () => {
      toast.error("Ocurrio un error al actulizar favorito");
    },
  });

  const handleAddFavorite = (item) => {
    const {
      productsid,
      image,
      articlenumber,
      productsummery,
      colornumber,
      regularprice,
    } = item.attributes;

    if (
      favoriteItem.favorites.data[0]?.attributes.favoritelist.find(
        (item) => item.productsid === productsid
      )
    ) {
      const favoritelist =
        favoriteItem.favorites.data[0]?.attributes.favoritelist
          .filter((item) => item.productsid !== productsid)
          .map(({ __typename, ...rest }) => rest);
      updateFavorite({
        variables: {
          id: favoriteItem.favorites.data[0]?.id,
          data: {
            favoritelist,
            users_permissions_user: authUser?.user?.id,
          },
        },
        refetchQueries: ["getFavoriteItem"],
        onCompleted: () => {
          toast.success("Your favorites were updated");
        },
      });
    } else {
      const favoritelist = {
        productsid: productsid,
        image: image,
        articlenumber: articlenumber,
        productsummery: productsummery,
        colornumber: colornumber,
        regularprice: parseFloat(regularprice),
      };

      if (favoriteItem.favorites.data.length === 0) {
        addFavorite({
          variables: {
            data: {
              favoritelist,
              users_permissions_user: authUser?.user?.id,
            },
          },
          refetchQueries: ["getFavoriteItem"],
        });
      } else {
        updateFavorite({
          variables: {
            id: favoriteItem.favorites.data[0]?.id,
            data: {
              favoritelist: [
                ...favoriteItem.favorites.data[0]?.attributes.favoritelist.map(
                  ({ __typename, ...rest }) => rest
                ),
                favoritelist,
              ],
            },
          },
          refetchQueries: ["getFavoriteItem"],
          onCompleted: () => {
            toast.success("Your favorites were updated");
          },
        });
      }
    }
  };

  const [items, setItems] = useState({});
  const { data: cartList } = useQuery(GET_SHIPPING_CART, {
    variables: {
      id: authUser?.user?.id,
    },
    onCompleted: (data) => {
      setItems(data);
    },
  });

  const [addToCart] = useMutation(CREATE_SHIPPING_CART, {
    onCompleted: () => toast.success("Added to cart."),
    onError: () => toast.error("Error adding to cart, please try again"),
  });

  const [updateCart, { updateLoading }] = useMutation(UPDATE_SHIPPING_CART, {
    onCompleted: () => {
      toast.success("Cart updated successfully");
    },
    onError: () => {
      toast.error("There was a problem, the cart could not be updated");
    },
  });

  useEffect(() => {
    setItems(cartList);
  }, [cartList, updateCart, addToCart]);
  const handleAddToCart = (producto, types) => {
    const {
      productsid,
      colornumber,
      image,
      productsummery,
      regularprice,
      supplierid,
      supplierproductname,
    } = producto.attributes;

    if (
      items &&
      items.cartItems?.data[0]?.attributes?.cartitem?.find(
        (item) => item.productId === productsid
      )
    ) {
      toast.success("The product is already in the cart.");
    } else {
      const cartitem = {
        quantity: "1",
        productId: productsid,
        colortype: colornumber,
        image,
        productSummery: productsummery,
        price: regularprice?.toString(),
        typeofpurchase: types,
        supplierid: supplierid,
        supplierproductname: supplierproductname,
        unitprice: regularprice.toString(),
      };

      if (items.cartItems.data.length === 0) {
        addToCart({
          variables: {
            data: {
              cartitem,
              users_permissions_user: authUser?.user?.id,
            },
          },
          refetchQueries: ["getCartItems", "getCartItem"],
        });
      } else {
        updateCart({
          variables: {
            id: items?.cartItems?.data[0]?.id,
            data: {
              cartitem: [
                ...items.cartItems.data[0]?.attributes.cartitem.map(
                  ({ __typename, ...rest }) => rest
                ),
                cartitem,
              ],
              users_permissions_user: authUser?.user?.id,
            },
          },
          refetchQueries: ["getCartItems", "getCartItem"],
        });
      }
    }
  };

  // Slider config
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  if (loading) return <></>;
  return (
    <>
      {dataProducts?.products?.data[0] ? (
        <>
          <Container className="content-products u-padding-none">
            <div className="row image-slider-container">
              <div className="u-margin-bottom-2 cd-content-button u-position-relative">
                <div className="content-like-heart">
                  <button
                    className={`btn-heart-favorite ${
                      favoriteList?.favorites?.data[0]?.attributes?.favoritelist.some(
                        (fav) =>
                          fav.productsid ===
                          dataProducts?.products?.data[0].attributes.productsid
                      ) && "mark-heart-check"
                    }`}
                    onClick={() =>
                      handleAddFavorite(dataProducts?.products?.data[0])
                    }
                  >
                    <HeartLike />
                  </button>
                </div>

                <CardProduct
                  confirmed={0}
                  data={dataProducts?.products?.data[0].attributes}
                />

                {(authUser.typeCustomer === "Admin" ||
                  authUser.typeCustomer === "Special customer") && (
                  <ButtonTL
                    disabled={updateLoading}
                    color="primary"
                    weight="light"
                    size="small"
                    onClick={() =>
                      handleAddToCart(dataProducts?.products?.data[0], "swatch")
                    }
                    style={{ width: "130px" }}
                    className="u-margin-top-2 u-margin-bottom-2"
                  >
                    {updateLoading ? <SmallLoader /> : "Quick Add"}
                  </ButtonTL>
                )}
              </div>
            </div>
          </Container>

          {/* <hr className="spacer-products" /> */}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ProductCardType;
