import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Container } from "../components/styled/ProductsStyled";

import { ORDER_ID, DELIVERY_STATUS } from "../querys/index";
import { getImageFromDrive } from "../services/api";
import {
  CREATE_DELIVERY_STATUS,
  UPDATE_DELIVERY_STATUS,
  UPDATE_ORDER,
} from "../mutations";
import { toast } from "react-toastify";
import { apiBase } from "../services/appenv";
import axios from "axios";
import { navigate } from "gatsby";

const InputField = ({ label, name, value, onChange, placeholder }) => (
  <div className="input-order-field" style={{ marginRight: "8px" }}>
    <label className="" htmlFor={name}>
      {label}
    </label>
    <input
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className=""
    />
  </div>
);

const DetailProducts = ({ products, onChange }) => (
  <>
    {products.length === 0 ? (
      ""
    ) : (
      <h4 className="font-semibold mt-4" style={{ marginBottom: "0.5em" }}>
        Detail Products
      </h4>
    )}

    {products.map((product, index) => (
      <div key={index} className="">
        {/* <h4 className="font-semibold">Product {index + 1}</h4> */}
        <div
          className="u-display-flex u-flex-wrap"
          style={{ marginBottom: "5px" }}
        >
          {product.image ? (
            <img
              src={getImageFromDrive(product.image)}
              alt="product Preview"
              className="thumbnail-order"
            />
          ) : (
            ""
          )}

          <div
            className="u-display-flex u-flex-wrap u-margin-left-1"
            style={{ width: "calc(100% - 100px)" }}
          >
            <InputField
              label="Article Number"
              name={`detailProducts.${index}.articleNumber`}
              value={product.articleNumber}
              onChange={onChange}
              placeholder="Article Number"
            />
            <InputField
              label="Color Number"
              name={`detailProducts.${index}.colorNumber`}
              value={product.colorNumber}
              onChange={onChange}
              placeholder="Color Number"
            />
            <InputField
              label="Quantity"
              name={`detailProducts.${index}.quantity`}
              value={parseInt(product.quantity)}
              onChange={onChange}
              placeholder="Quantity"
            />
            <InputField
              label="Total Price"
              name={`detailProducts.${index}.totalPrice`}
              value={parseFloat(product.totalPrice)}
              onChange={onChange}
              placeholder="Total Price"
            />
            <InputField
              label="Regular Price"
              name={`detailProducts.${index}.regularPrice`}
              value={product.regularPrice}
              onChange={onChange}
              placeholder="Regular Price"
            />
          </div>
        </div>
      </div>
    ))}
  </>
);

const DetailSwatches = ({ swatches, onChange }) => (
  <>
    {swatches.length === 0 ? (
      ""
    ) : (
      <h4 className="font-semibold mt-4" style={{ marginBottom: "0.5em" }}>
        Detail Swatch
      </h4>
    )}

    {swatches.map((swatch, index) => (
      <div key={index} className="orders-row-products">
        {/* <h4 className="font-semibold">Swatch {index + 1}</h4> */}
        <div
          className="u-display-flex u-flex-wrap"
          style={{ marginBottom: "5px" }}
        >
          {swatch.image ? (
            <img
              src={getImageFromDrive(swatch.image)}
              alt="Swatch Preview"
              className="thumbnail-order"
            />
          ) : (
            ""
          )}

          <div
            className="u-display-flex u-flex-wrap u-margin-left-1"
            style={{ width: "calc(100% - 100px)" }}
          >
            <InputField
              label="Swatch Article Number"
              name={`detailSwatch.${index}.articleNumber`}
              value={swatch.articleNumber}
              onChange={onChange}
              placeholder="Swatch Article Number"
            />
            <InputField
              label="Swatch Color Number"
              name={`detailSwatch.${index}.colorNumber`}
              value={swatch.colorNumber}
              onChange={onChange}
              placeholder="Swatch Color Number"
            />
            <InputField
              label="Swatch Quantity"
              name={`detailSwatch.${index}.quantity`}
              value={swatch.quantity}
              onChange={onChange}
              placeholder="Swatch Quantity"
            />
            <InputField
              label="Swatch Total Price"
              name={`detailSwatch.${index}.totalPrice`}
              value={swatch.totalPrice}
              onChange={onChange}
              placeholder="Swatch Total Price"
            />
            <InputField
              label="Swatch Regular Price"
              name={`detailSwatch.${index}.regularPrice`}
              value={swatch.regularPrice}
              onChange={onChange}
              placeholder="Swatch Regular Price"
            />
          </div>
        </div>
      </div>
    ))}
  </>
);

const AllOrdersID = ({ id }) => {
  const [orderID, setOrderID] = useState({});

  const { loading, error } = useQuery(ORDER_ID, {
    variables: { orderhistoryId: id },
    onCompleted: (data) => {
      setOrderID(data);
    },
  });

  const token = localStorage.getItem("token");
  const HEADERS = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  const { data: deliveryData } = useQuery(DELIVERY_STATUS, {
    variables: { orderId: id },
    fetchPolicy: "network-only",
  });

  const [orderDescription, setOrderDescription] = useState("");
  const [initialFormData, setInitialFormData] = useState(null);
  const [previousShippingValues, setPreviousShippingValues] = useState({
    expectedDeliveryDateStatus: "",
    trackingNumber: "",
    trackingURL: "",
    carrier: "",
    country: "",
  });

  const [expectedDeliveryDateStatus, setExpectedDeliveryDateStatus] =
    useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackingURL, setTrackingURL] = useState("");
  const [carrier, setCarrier] = useState("");
  const [country, setCountry] = useState("");

  const [showAdditionalFields, setShowAdditionalFields] = useState(false);

  const [form1HasChanges, setForm1HasChanges] = useState(false);

  const [deliveryStatusId, setDeliveryStatusId] = useState(null);

  useEffect(() => {
    const newData = orderID?.orderhistory?.data.attributes || {};
    const initializedData = initializeFormData(newData);
    setFormData(initializedData);
    setInitialFormData(initializedData);
  }, [orderID]);

  const [updateDeliveryStatus] = useMutation(UPDATE_DELIVERY_STATUS);
  const [createDelivery] = useMutation(CREATE_DELIVERY_STATUS);
  const [updateOrderhistory] = useMutation(UPDATE_ORDER);

  const initializeFormData = (data) => ({
    amount: data.amount || 0,
    email: data.email || "",
    username:
      data.firstname ||
      data?.users_permissions_user?.data?.attributes?.firstName ||
      "",
    shippingFee: data.shippingFee || 0,
    companyName: data.companyName || "",
    dateOrder: data.dateOrder || "",
    payment_method: data.payment_method || "",
    expectedDeliveryDate: data.expectedDeliveryDate || null,
    shippingDetails: data.shippingDetails || "",
    shippingAddress: {
      address: data.shippingAddress?.address || "",
      city: data.shippingAddress?.city || "",
      shippingUser: data.shippingAddress?.shippingUser || "",
      phoneNumber: data.shippingAddress?.phoneNumber || "",
      zipcode: data.shippingAddress?.zipcode || "",
      street: data.shippingAddress?.street || "",
    },
    detailProducts: Array.isArray(data.detailProducts)
      ? data.detailProducts.map((product) => ({
          articleNumber: product.articleNumber || "",
          colorNumber: product.colorNumber || "",
          quantity: product.quantity || null,
          totalPrice: product.totalPrice || null,
          regularPrice: product.regularPrice || null,
          image: product.image || "",
        }))
      : [],
    detailSwatch: Array.isArray(data.detailSwatch)
      ? data.detailSwatch.map((swatch) => ({
          articleNumber: swatch.articleNumber || "",
          colorNumber: swatch.colorNumber || "",
          quantity: swatch.quantity || null,
          totalPrice: swatch.totalPrice || null,
          regularPrice: swatch.regularPrice || null,
          image: swatch.image || "",
        }))
      : [],
    status: deliveryData?.deliveryStatuses?.data[0]?.attributes?.status || "",
    country: deliveryData?.deliveryStatuses?.data[0]?.attributes?.country || "",
    // data.delivery_status?.data?.attributes?.status
  });

  const [formData, setFormData] = useState(
    initializeFormData(orderID?.orderhistory?.data.attributes || {})
  );

  useEffect(() => {
    if (deliveryData?.deliveryStatuses?.data?.[0]) {
      const delivery = deliveryData.deliveryStatuses.data[0].attributes;

      // Actualizar status y description de manera síncrona
      setFormData((prev) => ({
        ...prev,
        status: delivery.status || prev.status,
      }));
      setOrderDescription(delivery.description || "");

      // Actualizar los demás campos
      setExpectedDeliveryDateStatus(delivery.estimateDeliveryDate || "");
      setCarrier(delivery.carrier || "");
      setCountry(delivery.country || "");
      setTrackingNumber(delivery.trackingNumber || "");
      setTrackingURL(delivery.trackingUrl || "");
      setDeliveryStatusId(deliveryData.deliveryStatuses.data[0].id);
    }
  }, [deliveryData]);

  const getStatusMessage = (username) => ({
    Ordered: `Hi ${username ? username + "," : ""}
Thank you for your order.`,

    //     "Confirmed order": `Hi ${username ? username + "," : ""}
    // We have confirmed your order and will notify you once it is ready for shipment. Thank you!`,

    //     "Preparing Delivery": `Hi ${username ? username + "," : ""}
    // We are now preparing your delivery. We will notify you once it is ready.`,

    "Shipped (from Japan)": `Hi ${username ? username + "," : ""}
We have just shipped your order from Japan. Here are your shipping details.`,

    "Shipped (arrived in your country)": `Hi ${username ? username + "," : ""}
Great news! Your order is out for delivery and will reach you soon.`,

    Arrived: `Hi ${username ? username + "," : ""}
Our product has arrived. Have you not received it yet? Please let us know at info@textilelibrary-takihyo.com.`,
  });

  const hasValueChanged = (current, initial) => {
    if (current === initial) return false;
    if (current === null || initial === null) return true;
    if (current === undefined || initial === undefined) return true;

    return current.toString() !== initial.toString();
  };

  const hasArrayChanges = (currentArray, initialArray, itemType) => {
    if (!currentArray || !initialArray) return false;
    if (currentArray.length !== initialArray.length) {
      return true;
    }

    for (let i = 0; i < currentArray.length; i++) {
      const current = currentArray[i];
      const initial = initialArray[i];

      const fieldsToCheck = [
        "articleNumber",
        "colorNumber",
        "quantity",
        "totalPrice",
        "regularPrice",
      ];

      for (const field of fieldsToCheck) {
        if (hasValueChanged(current[field], initial[field])) {
          return true;
        }
      }
    }
    return false;
  };
  const hasAddressChanges = (currentAddress, initialAddress) => {
    if (!currentAddress || !initialAddress) return false;

    const addressFields = [
      "address",
      "city",
      "shippingUser",
      "phoneNumber",
      "zipcode",
      "street",
    ];

    for (const field of addressFields) {
      const currentValue = currentAddress[field]?.toString() || "";
      const initialValue = initialAddress[field]?.toString() || "";

      if (currentValue !== initialValue) {
        return true;
      }
    }
    return false;
  };
  const hasRealChanges = (currentData, initialData) => {
    if (!initialData) return false;

    const basicFields = [
      "amount",
      "email",
      "username",
      "shippingFee",
      "companyName",
      "dateOrder",
      "payment_method",
      "expectedDeliveryDate",
      "shippingDetails",
    ];

    for (const field of basicFields) {
      if (hasValueChanged(currentData[field], initialData[field])) {
        return true;
      }
    }

    if (
      hasAddressChanges(
        currentData.shippingAddress,
        initialData.shippingAddress
      )
    ) {
      return true;
    }

    if (
      hasArrayChanges(
        currentData.detailProducts,
        initialData.detailProducts,
        "Product"
      )
    ) {
      return true;
    }

    if (
      hasArrayChanges(
        currentData.detailSwatch,
        initialData.detailSwatch,
        "Swatch"
      )
    ) {
      return true;
    }

    return false;
  };

  const handleSelectCarrier = (e) => {
    setCarrier(e.target.value);
  };

  const handleSelectCountry = (e) => {
    setCountry(e.target.value);
  };

  const handleSubmitDelivery = async (shouldSendEmail = false) => {
    // e.preventDefault();

    const deliveryDataForm = {
      status: formData.status,
      estimateDeliveryDate: expectedDeliveryDateStatus || null,
      carrier,
      country,
      trackingNumber,
      trackingUrl: carrier === "other" || (carrier === "fedex" && country === "OTHER") ? trackingURL : "",
      description: orderDescription,
      orderhistory: id,
    };

    try {
      if (deliveryStatusId) {
        await updateDeliveryStatus({
          variables: {
            id: deliveryStatusId,
            data: deliveryDataForm,
          },
          refetchQueries: ["deliveryStatus"],
        });
      } else {
        await createDelivery({
          variables: {
            data: deliveryDataForm,
          },
          refetchQueries: ["deliveryStatus"],
        });
      }
      if (shouldSendEmail) {
        handleEmailOrderStatus();
        toast.success(
          `Delivery status ${
            deliveryStatusId ? "updated" : "created"
          } successfully`
        );
      } else {
        toast.success("Delivery data was saved successfully!");
      }

      // navigate("/orders-history");
    } catch (error) {
      
      toast.error(
        `Error ${deliveryStatusId ? "updating" : "creating"} delivery status`
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Caso especial para el campo status
    if (name === "status") {
      setFormData((prevData) => ({
        ...prevData,
        status: value,
      }));
      const delivery = deliveryData?.deliveryStatuses?.data[0]?.attributes;

      // setOrderDescription(delivery?.description || "");
      setExpectedDeliveryDateStatus(delivery?.estimateDeliveryDate || "");
      setCarrier(delivery?.carrier || "");
      setCountry(delivery?.carrier || "");
      setTrackingNumber(delivery?.trackingNumber || "");
      setTrackingURL(delivery?.trackingUrl || "");
      setDeliveryStatusId(deliveryData?.deliveryStatuses?.data[0]?.id);

      setOrderDescription(getStatusMessage(formData.username)[value] || "");

      // setOrderDescription(statusMessages[value] || "");
    } else if (name === "username") {
      setFormData((prevData) => {
        const newData = {
          ...prevData,
          username: value,
        };

        if (newData.status) {
          setOrderDescription(getStatusMessage(value)[newData.status] || "");
        }

        const realChanges = hasRealChanges(newData, initialFormData);
        setForm1HasChanges(realChanges);
        return newData;
      });
    }
    // Para todos los demás campos
    else {
      setFormData((prevData) => {
        // Crear copia del estado actual
        const newData = { ...prevData };
        const keys = name.split(".");

        // 1. Manejo de campos de dirección
        if (keys[0] === "shippingAddress") {
          newData.shippingAddress = {
            ...newData.shippingAddress,
            [keys[1]]: value,
          };
        }

        // 2. Manejo de productos
        else if (keys[0] === "detailProducts") {
          const [, index, field] = keys;
          const products = [...(newData.detailProducts || [])];

          if (!products[index]) {
            products[index] = {};
          }

          products[index] = {
            ...products[index],
            [field]: value,
          };

          newData.detailProducts = products;
        }

        // 3. Manejo de swatches
        else if (keys[0] === "detailSwatch") {
          const [, index, field] = keys;
          const swatches = [...(newData.detailSwatch || [])];

          if (!swatches[index]) {
            swatches[index] = {};
          }

          swatches[index] = {
            ...swatches[index],
            [field]: value,
          };

          newData.detailSwatch = swatches;
        } else {
          newData[name] = value;
        }
        const realChanges = hasRealChanges(newData, initialFormData);
        setForm1HasChanges(realChanges);

        return newData;
      });
    }
  };

  const handleEmailOrderStatus = () => {
    axios
      .post(
        `${apiBase}/api/mail-order-history`,
        JSON.stringify(orderData),
        HEADERS
      )
      .then((response) => {
        toast.success(`Email send successfully!`);
        // console.log(response, "response");
        // navigate("/orders-history");
      })
      .catch((error) => {
        toast.error(`Error, please try again`);
      })
      .finally(() => {});
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedFields = {};

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== orderID[key]) {
        updatedFields[key] = formData[key];
      }
    });

    try {
      await updateOrderhistory({
        variables: {
          id: id,
          data: updatedFields,
        },
      });
      setForm1HasChanges(false);
      setInitialFormData(formData);
      toast.success("Order updated successfully");
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const handleCheckboxChange = (e) => {
    setShowAdditionalFields(e.target.checked);
  };

  const orderData = {
    status: formData.status,
    username: formData.username,
    email: formData.email,
    description: orderDescription,
    expectedDeliveryDateStatus,
    trackingNumber,
    trackingURL,
    carrier,
    country,
    expectedDeliveryDate: formData.expectedDeliveryDate,
    shippingDetails: formData.shippingDetails,
    order: id,

    shippingAddress: formData.shippingAddress,
    company: formData.companyName,
    detailProduct: formData?.detailProduct,
    detailSwatch: formData?.detailSwatch,
  };

  const shouldShowInputs =
    formData.status === "Shipped (from Japan)" ||
    formData.status === "Shipped (arrived in your country)";

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          marginTop: "3em",
          marginBottom: "3em",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          opacity: "0.6",
        }}
      >
        <div
          className="loading loading-card"
          style={{
            width: "50px",
            height: "50px",
            margin: "3em auto",
            marginBottom: "2em",
          }}
        ></div>
        <p>Loading, please wait...</p>
      </div>
    );
  if (error)
    return (
      <div
        style={{
          display: "flex",
          marginTop: "5em",
          marginBottom: "5em",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          opacity: "0.6",
        }}
      >
        <svg
          style={{ width: "100px", height: "100px" }}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M2.20164 18.4695L10.1643 4.00506C10.9021 2.66498 13.0979 2.66498 13.8357 4.00506L21.7984 18.4695C22.4443 19.6428 21.4598 21 19.9627 21H4.0373C2.54022 21 1.55571 19.6428 2.20164 18.4695Z"
              stroke="#dedede"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>{" "}
            <path
              d="M12 9V13"
              stroke="#dedede"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>{" "}
            <path
              d="M12 17.0195V17"
              stroke="#dedede"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>{" "}
          </g>
        </svg>
        <p style={{ marginTop: "1em" }}>
          You don’t have access to this section.
        </p>
      </div>
    );

  return (
    <Container className="container">
      <div className="row">
        <h2 className="font-semibold mt-4 u-margin-bottom-1">
          Edit Order #{id}
        </h2>

        <form className="col-9" onSubmit={handleSubmit}>
          <div className="">
            <div className="u-display-flex u-flex-wrap u-margin-bottom-1">
              <InputField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
              />
              <InputField
                label="Amount"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                placeholder="Amount"
              />
              <InputField
                label="Username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                placeholder="Username"
              />
              <InputField
                label="Shipping Fee"
                name="shippingFee"
                value={formData.shippingFee}
                onChange={handleChange}
                placeholder="Shipping Fee"
              />
              <InputField
                label="Company Name"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                placeholder="Company Name"
              />
              <InputField
                label="Date Order"
                name="dateOrder"
                value={formData.dateOrder}
                onChange={handleChange}
                placeholder="Date Order"
              />
              <InputField
                label="Payment Method"
                name="payment_method"
                value={formData.payment_method}
                onChange={handleChange}
                placeholder="Payment Method"
              />
            </div>

            <h4 className="" style={{ marginBottom: "0.5em" }}>
              Shipping Address
            </h4>

            <div className="u-display-flex u-flex-wrap">
              <InputField
                label="Address"
                name="shippingAddress.address"
                value={formData.shippingAddress.address}
                onChange={handleChange}
                placeholder="Address"
              />
              <InputField
                label="City"
                name="shippingAddress.city"
                value={formData.shippingAddress.city}
                onChange={handleChange}
                placeholder="City"
              />
              <InputField
                label="Shipping User"
                name="shippingAddress.shippingUser"
                value={formData.shippingAddress.shippingUser}
                onChange={handleChange}
                placeholder="Shipping User"
              />
              <InputField
                label="Phone Number"
                name="shippingAddress.phoneNumber"
                value={formData.shippingAddress.phoneNumber}
                onChange={handleChange}
                placeholder="Phone Number"
              />
              <InputField
                label="Zipcode"
                name="shippingAddress.zipcode"
                value={formData.shippingAddress.zipcode}
                onChange={handleChange}
                placeholder="Zipcode"
              />
              <InputField
                label="Street"
                name="shippingAddress.street"
                value={formData.shippingAddress.street}
                onChange={handleChange}
                placeholder="Street"
              />
            </div>

            {/* <h4
              className=""
              style={{ marginBottom: "0.5em", marginTop: "20px" }}
            >
              Shipping Details
            </h4>
            <div className="u-display-flex u-flex-wrap">
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div style={{ display: "flex" }}>
                <div className="input-order-field">
                  <label htmlFor="expectedDeliveryDate">
                    Expected Delivery Date:
                  </label>
                  <input
                    type="date"
                    id="expectedDeliveryDate"
                    name="expectedDeliveryDate"
                    value={formData.expectedDeliveryDate}
                    onChange={handleChange}
                    style={{
                      display: "block",
                      width: "100%",
                      padding: "5px",
                      marginTop: "5px",
                    }}
                  />
                </div>

                <div className="input-order-field">
                  <label htmlFor="shippingDetails">Shipping Details</label>
                  <input
                    id="shippingDetails"
                    name="shippingDetails"
                    placeholder="Shipping Details"
                    value={formData.shippingDetails}
                    onChange={handleChange}
                    style={{
                      display: "block",
                      width: "300px",
                      height: "30px",
                      padding: "5px",
                      marginTop: "5px",
                      resize: "none",
                    }}
                  />
                </div>
              </div>
            </div> */}

            <DetailProducts
              products={formData?.detailProducts}
              onChange={handleChange}
            />

            <DetailSwatches
              swatches={formData?.detailSwatch}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="order-button">
            Save Data
          </button>
        </form>
        <form className="col-3">
          <div className=" delivery-status">
            <div className="delivery-status__select">
              <div style={{ marginBottom: "0.5em" }}>Delivery Status</div>
              <select
                id="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
                className=""
              >
                <option value="">Select Status</option>
                <option value="Ordered" disabled>Ordered</option>
                {/* <option value="Confirmed order">Confirmed order</option>
                <option value="Preparing Delivery">Preparing Delivery</option> */}
                <option value="Shipped (from Japan)">
                  Shipped (from Japan){" "}
                </option>
                {/* <option value="Shipped (arrived in your country)">
                  Shipped (arrived in your country)
                </option>
                <option value="Arrived">Arrived</option> */}
              </select>

              {shouldShowInputs && (
                <div>
                  {/* <div
                    className="input-order-field"
                    style={{ marginTop: "8px" }}
                  >
                    <label htmlFor="expectedDeliveryDate">
                      Estimate delivery date:
                    </label>
                    <input
                      type="date"
                      id="expectedDeliveryDateStatus"
                      name="expectedDeliveryDateStatus"
                      value={expectedDeliveryDateStatus}
                      onChange={(e) =>
                        setExpectedDeliveryDateStatus(e.target.value)
                      }
                      style={{
                        display: "block",
                        width: "100%",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                    />
                  </div> */}

                  <div className="input-order-field" style={{marginTop: "10px"}}>
                    <label htmlFor="carrier">Carrier</label>
                    <select
                      id="carrier"
                      name="carrier"
                      value={carrier}
                      onChange={handleSelectCarrier}
                      style={{
                        display: "block",
                        width: "100%",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <option value="" disabled selected>
                        Select Carrier
                      </option>
                      <option value="fedex">FedEx</option>
                      <option value="dhl">DHL</option>
                      <option value="ups">UPS</option>
                      <option value="other">Other</option>
                    </select>
                  </div>

                  <div className="input-order-field">
                    <label htmlFor="trackingNumber">Tracking #:</label>
                    <input
                      type="text"
                      id="trackingNumber"
                      name="trackingNumber"
                      value={trackingNumber}
                      onChange={(e) => setTrackingNumber(e.target.value)}
                      style={{
                        display: "block",
                        width: "100%",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                    />
                  </div>

                  {carrier === "fedex" ? (
                    <div className="input-order-field">
                      <label htmlFor="country">Country</label>
                      <select
                        id="country"
                        name="country"
                        value={country}
                        onChange={handleSelectCountry}
                        style={{
                          display: "block",
                          width: "100%",
                          padding: "5px",
                          marginTop: "5px",
                        }}
                      >
                        <option value="" disabled selected>
                          Select Country
                        </option>
                        <option value="US">USA</option>
                        <option value="CA">CANADA</option>
                        <option value="OTHER">Others</option>
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {carrier === "other" ||
                  (carrier === "fedex" && country === "OTHER") ? (
                    <div className="input-order-field">
                      <label htmlFor="trackingURL">Tracking URL:</label>
                      <input
                        type="text"
                        id="trackingURL"
                        name="trackingURL"
                        value={trackingURL}
                        onChange={(e) => setTrackingURL(e.target.value)}
                        style={{
                          display: "block",
                          width: "100%",
                          padding: "5px",
                          marginTop: "5px",
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {/* {carrier === "fedex" && country === "OTHER" ? (
                    <div className="input-order-field">
                      <label htmlFor="trackingURL">Tracking URL:</label>
                      <input
                        type="text"
                        id="trackingURL"
                        name="trackingURL"
                        value={trackingURL}
                        onChange={(e) => setTrackingURL(e.target.value)}
                        style={{
                          display: "block",
                          width: "100%",
                          padding: "5px",
                          marginTop: "5px",
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
              )}

              <textarea
                name="orderDescription"
                id="orderDescription"
                placeholder="Description"
                onChange={(e) => setOrderDescription(e.target.value)}
                value={orderDescription}
                style={{
                  marginTop: "10px",
                  padding: "5px",
                  width: "100%",
                  height: "150px",
                  resize: "none",
                }}
              ></textarea>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  type="button"
                  className="order-button"
                  onClick={() => handleSubmitDelivery(false)}
                  disabled={form1HasChanges}
                  title={form1HasChanges ? "Please save order data first" : ""}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="order-button"
                  onClick={() => handleSubmitDelivery(true)}
                  disabled={form1HasChanges}
                  title={form1HasChanges ? "Please save order data first" : ""}
                >
                  Save & Send
                </button>{" "}
              </div>
              {form1HasChanges && (
                <p className="validate-error-mail-order">
                  Please save order data first.
                </p>
              )}
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};
export default AllOrdersID;
