export const formatPrice = priceWithDecimals => {
  if (!priceWithDecimals) {
    return "Please add a product to cart"
  }

  const realPrice = parseInt(priceWithDecimals) / 100
  return realPrice.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })
}

export const handleSortAlphabetically = data => {
  data.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1
    }
    return 0
  })
  return data
}


export const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  if (isNaN(date)) return "Invalid Date";

  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0"); 
  const year = date.getFullYear(); 

  return `${month}-${day}-${year}`;
};