import gql from "graphql-tag";

export const CURRENT_USER = gql`
  query refreshCurrentUser {
    me {
      id
      username
      email
      confirmed
      role {
        id
      }
    }
  }
`;

export const USER_DATA = gql`
  query refeshUserData($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          lastName
          email
          firstName
          companyName
          confirmed
          favorites {
            data {
              id
              attributes {
                productsid
              }
            }
          }
          harikae {
            data {
              attributes {
                harikae
              }
            }
          }
          typecustomer
          tagForMe
          addresses {
            data {
              id
              attributes {
                companyName
                address
                addressOptional
                main
                cityName
                country
                zipcode
                state
                phone
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ADDRESS_BY_ID = gql`
  query getAddress($id: ID!) {
    addresses(filters: { users_permissions_user: { id: { eq: $id } } }) {
      data {
        id
        attributes {
          lastname
          firstname
          companyName
          address
          cityName
          street
          aptNumber
          phone
          zipcode
        }
      }
    }
  }
`;

export const GET_MAIN_ADDRESS = gql`
  query getMainAddress($id: ID!) {
    addresses(filters: { users_permissions_user: { id: { eq: $id } } }) {
      data {
        id
        attributes {
          lastname
          firstname
          companyName
          address
          cityName
          street
          aptNumber
          phone
          zipcode
          state
          country
          addressOptional
        }
      }
    }
  }
`;

export const GET_ADDRESSES = gql`
  query getSecontaryAddress($id: ID!) {
    addresses(filters: { users_permissions_user: { id: { eq: $id } } }) {
      data {
        id
        attributes {
          lastname
          firstname
          companyName
          address
          cityName
          street
          aptNumber
          phone
          zipcode
          state
          country
          main
          addressOptional
        }
      }
    }
  }
`;

/**
 * Query: get favorite according to user id
 */

export const FAVORITE_QUERY = gql`
  query refreshFavorites($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        attributes {
          favorites {
            data {
              attributes {
                productid
                image
                colornumber
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_FAVORITE_ITEMS = gql`
  query getFavoriteItem($id: ID!) {
    favorites(filters: { users_permissions_user: { id: { eq: $id } } }) {
      data {
        id
        attributes {
          favoritelist {
            articlenumber
            image
            colornumber
            productsid
            productsummery
            regularprice
          }
        }
      }
    }
  }
`;

/**
 * Query: get favorit according to user id
 */

export const GET_FAVORITE = gql`
  query getFavorites($id: ID!) {
    favorites(
      filters: { users_permissions_user: { id: { eq: $id } } }
      pagination: { limit: 5000 }
    ) {
      data {
        id
        attributes {
          productsid
          image
          colornumber
          articlenumber
          productsummery
          regularprice
        }
      }
    }
  }
`;

export const ORDER_HISTORY = gql`
  query orderHistory($id: ID!) {
    orderhistories(
      sort: "createdAt:DESC"
      filters: { users_permissions_user: { id: { eq: $id } } }
    ) {
      data {
        id
        attributes {
          amount
          email
          username
          shippingFee
          companyName
          dateOrder
          status
          payment_method
          shippingAddress {
            address
            city
            shippingUser
            phoneNumber
            zipcode
            street
          }
          detailProducts {
            articleNumber
            colorNumber
            quantity
            totalPrice
            regularPrice
            image
          }
          detailSwatch {
            articleNumber
            colorNumber
            quantity
            totalPrice
            regularPrice
            image
          }
          createdAt
        }
      }
    }
  }
`;

// export const ADDRESS_QUERY = gql`
//   {
//     addresses {
//       id
//       firstname
//       lastname
//     }
//   }
// `

//Orders
export const FILTER_ORDERID = gql`
  query users($usersPermissionsUserId: ID) {
    usersPermissionsUser(id: $usersPermissionsUserId) {
      data {
        attributes {
          orderhistories {
            data {
              id
            }
          }
        }
      }
    }
  }
`;
export const ORDER_ID = gql`
  query orderHistory($orderhistoryId: ID) {
    orderhistory(id: $orderhistoryId) {
      data {
        id
        attributes {
          amount
          email
          username
          shippingFee
          companyName
          dateOrder
          payment_method
          shippingAddress {
            address
            city
            shippingUser
            phoneNumber
            zipcode
            street
          }
          detailProducts {
            articleNumber
            colorNumber
            quantity
            totalPrice
            regularPrice
            image
          }
          detailSwatch {
            articleNumber
            colorNumber
            quantity
            totalPrice
            regularPrice
            image
          }
          users_permissions_user {
            data {
              attributes {
                firstName
                lastName
              }
            }
          }
          delivery_status {
            data {
              id
              attributes {
                status
                estimateDeliveryDate
                carrier
                country
                trackingNumber
                trackingUrl
                description
              }
            }
          }
          firstname
          status
          expectedDeliveryDate
          shippingDetails
          createdAt
        }
      }
    }
  }
`;

export const USER_ORDER = gql`
  query orderHistory($id: ID!) {
    orderhistories(filters: { id: { eq: $id } }) {
      data {
        id
        attributes {
          amount
          email
          username
          shippingFee
          companyName
          dateOrder
          payment_method
          shippingAddress {
            address
            city
            shippingUser
            phoneNumber
            zipcode
            street
          }
          detailProducts {
            articleNumber
            colorNumber
            quantity
            totalPrice
            regularPrice
            image
          }
          detailSwatch {
            articleNumber
            colorNumber
            quantity
            totalPrice
            regularPrice
            image
          }

          createdAt
        }
      }
    }
  }
`;

export const GET_FEDEX_ORDER = gql`
  query {
    fedexorders(filters: { id: { eq: 1 } }) {
      data {
        id
        attributes {
          dataFedex
        }
      }
    }
  }
`;

export const getAllOrders = gql`
  query orderHistory {
    orderhistories(
      sort: "createdAt:DESC"
      pagination: { limit: 1000, start: 0 }
    ) {
      data {
        id
        attributes {
          delivery_status {
            data {
              attributes {
                status
              }
            }
          }
          amount
          email
          username
          shippingFee
          companyName
          dateOrder
          payment_method
          shippingAddress {
            address
            city
            shippingUser
            phoneNumber
            zipcode
            street
          }
          detailProducts {
            articleNumber
            colorNumber
            quantity
            totalPrice
            regularPrice
            image
          }
          detailSwatch {
            articleNumber
            colorNumber
            quantity
            totalPrice
            regularPrice
            image
          }
          users_permissions_user {
            data {
              attributes {
                firstName
                lastName
              }
            }
          }
          status
          createdAt
        }
      }
    }
  }
`;

export const getFedexOrder = gql`
  query getFedexOrder($order_id: ID!) {
    fedexorders(filters: { id: { eq: $order_id } }) {
      data {
        id
        attributes {
          dataFedex
          category
          service_type
          shipping_pdf
          commercial_invoice_pdf
        }
      }
    }
  }
`;

export const GET_ORDERS = gql`
  query orderHistory {
    orderhistories {
      data {
        attributes {
          amount
          email
          username
          shippingFee
          companyName
          dateOrder
          payment_method
          shippingAddress {
            address
            city
            shippingUser
            phoneNumber
            zipcode
            street
          }
          detailProducts {
            articleNumber
            colorNumber
            quantity
            totalPrice
            regularPrice
            image
          }
          detailSwatch {
            articleNumber
            colorNumber
            quantity
            totalPrice
            regularPrice
            image
          }

          createdAt
        }
      }
    }
  }
`;

// { material: { contains: $material1 } }
// { material: { contains: $material2 } }
// { material: { contains: $material3 } }
// { material: { contains: $material4 } }
// { material: { contains: $material5 } }
// { material: { contains: $material6 } }
// { material: { contains: $material7 } }
// { material: { contains: $material8 } }
// { material: { contains: $material9 } }

// $material1: String
// $material2: String
// $material3: String
// $material4: String
// $material5: String
// $material6: String
// $material7: String
// $material8: String
// $material9: String



export const FILTER_PRODUCTS = gql`
  query getProducts(
    $page: Int
    $size: Int
    $vip: String
    $typeSort: [String]
    $search: String
  ) {
    products(
      pagination: { page: $page, pageSize: $size }
      sort: $typeSort
      filters: {
        customerspcialorregular: { eq: $vip }
        or: [
          { productsid: { containsi: $search } }
        ]
      }
    ) {
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
      data {
        id
        attributes {
          supplierproductname
          supplierid
          searchword
          productsummery
          productsid
          collectiontag
          productdetail
          colornumber
          inventory
          detailattribute
          fabricname
          articlenumber
          customerspcialorregular
          image
          weightnumber
          detailcategory
          productdetail
          material
          materialcategory
          materialsummery
          regularprice
          priceb
          video
          color
          functions
          finish
          constructions
          patternanddesign
          finishlookandfeel
          subimage1
          subimage2
          subimage3
          subimage4
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query getProducts(
    $page: Int
    $size: Int
    $vip: String
    $typeSort: [String]
    $search: String
    $searchWord: String
    $searchWord2: String
    $searchWord3: String
    $collection: String
    $collection2: String
    $collection3: String
    $color: String
    $functions: String
    $material: String
    $finish: String
    $constructions: String
    $patternanddesign: String
    $finishlookandfeel: String
    $priceRange: [Float]
    $sustainable: String
    $typeOrder: String
    $fabricname: String
    $weight: [Float]
    $tag: String
  ) {
    products(
      pagination: { page: $page, pageSize: $size }
      sort: $typeSort
      filters: {
        customerspcialorregular: { eq: $vip }
        or: [
          { fabricname: { containsi: $search } }
          { productsid: { containsi: $search } }
          { productsummery: { containsi: $search } }
          { productdetail: { containsi: $search } }
          { searchword: { containsi: $search } }
          { collectiontag: { containsi: $search } }
          { material: { containsi: $search } }
          { detailattribute: { containsi: $search } }
          { supplierid: { containsi: $search } }
          { supplierproductname: { containsi: $search } }
          { color: { containsi: $search } }
          { functions: { containsi: $search } }
          { finish: { containsi: $search } }
          { constructions: { containsi: $search } }
          { patternanddesign: { containsi: $search } }
          { finishlookandfeel: { containsi: $search } }
          { sustainable: { eq: $search } }
          { stockoperationorbyorder: { eq: $search } }
        ]
        and: [
          { collectiontag: { containsi: $tag } }
          { material: { containsi: $material } }
          { fabricname: { containsi: $fabricname } }
          { sustainable: { containsi: $sustainable } }
          { stockoperationorbyorder: { contains: $typeOrder } }
          { searchword: { containsi: $searchWord } }
          { searchword: { containsi: $searchWord2 } }
          { searchword: { containsi: $searchWord3 } }
          { collectiontag: { containsi: $collection } }
          { collectiontag: { containsi: $collection2 } }
          { collectiontag: { containsi: $collection3 } }
          { color: { containsi: $color } }
          { functions: { containsi: $functions } }
          { finish: { containsi: $finish } }
          { constructions: { containsi: $constructions } }
          { patternanddesign: { containsi: $patternanddesign } }
          { finishlookandfeel: { containsi: $finishlookandfeel } }
          { regularprice: { between: $priceRange } }
          { weightnumber: { between: $weight } }
        ]
      }
    ) {
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
      data {
        id
        attributes {
          supplierproductname
          supplierid
          searchword
          productsummery
          productsid
          collectiontag
          productdetail
          colornumber
          inventory
          detailattribute
          fabricname
          articlenumber
          customerspcialorregular
          image
          weightnumber
          detailcategory
          productdetail
          material
          materialcategory
          materialsummery
          regularprice
          priceb
          video
          color
          functions
          finish
          constructions
          patternanddesign
          finishlookandfeel
          subimage1
          subimage2
          subimage3
          subimage4
        }
      }
    }
  }
`;
export const GET_PRODUCTS_TYPE = gql`
  query getProducts($vip: String, $typeSort: [String], $collection: String) {
    products(
      sort: $typeSort
      pagination: { limit: 10 }
      filters: {
        customerspcialorregular: { eq: $vip }
        and: [{ collectiontag: { containsi: $collection } }]
      }
    ) {
      data {
        id
        attributes {
          supplierproductname
          supplierid
          searchword
          productsummery
          productsid
          collectiontag
          productdetail
          colornumber
          inventory
          detailattribute
          fabricname
          articlenumber
          customerspcialorregular
          image
          weightnumber
          detailcategory
          productdetail
          material
          materialcategory
          materialsummery
          regularprice
          priceb
          video
          color
          functions
          finish
          constructions
          patternanddesign
          finishlookandfeel
          subimage1
          subimage2
          subimage3
          subimage4
        }
      }
    }
  }
`;

export const GET_PRODUCTS_FAVORITE = gql`
  query getProductsFavorite($vip: String, $search: [String]) {
    products(
      pagination: { limit: 5000 }
      filters: {
        customerspcialorregular: { eq: $vip }
        or: [{ productsid: { in: $search } }]
      }
    ) {
      data {
        attributes {
          productsummery
          productsid
          colornumber
          articlenumber
          image
          regularprice
        }
      }
    }
  }
`;

export const FIND_PRODUCT_ID = gql`
  query getProducts($search: String) {
    products(filters: { productsid: { eq: $search } }) {
      data {
        attributes {
          productsid
        }
      }
    }
  }
`;

export const PRODUCT_BY_ID = gql`
  query getProductId($product: String, $typeUser: String) {
    products(
      filters: {
        productsid: { eq: $product }
        customerspcialorregular: { eq: $typeUser }
      }
    ) {
      data {
        attributes {
          supplierproductname
          supplierid
          searchword
          productsummery
          productsid
          collectiontag
          productdetail
          colornumber
          inventory
          detailattribute
          fabricname
          articlenumber
          image
          weightnumber
          detailcategory
          productdetail
          material
          materialcategory
          materialsummery
          regularprice
          priceb
          video
          color
          functions
          finish
          constructions
          patternanddesign
          finishlookandfeel
          subimage1
          subimage2
          subimage3
          subimage4
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query ($email: String) {
    usersPermissionsUsers(filters: { email: { eq: $email } }) {
      data {
        attributes {
          email
        }
      }
    }
  }
`;

export const DELIVERY_STATUS = gql`
  query deliveryStatus($orderId: ID!) {
    deliveryStatuses(filters: { orderhistory: { id: { eq: $orderId } } }) {
      data {
        id
        attributes {
          status
          estimateDeliveryDate
          carrier
          country
          trackingNumber
          trackingUrl
          description
        }
      }
    }
  }
`;
