import React, { useMemo, useState, useEffect } from "react";
import ProductCardType from "./cards/ProductCardType";
import { useStaticQuery, graphql } from "gatsby";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import Slider from "react-slick";

const HomeTypeSection = ({ type }) => {
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 1200
  );

  useEffect(() => {
    if (typeof window === "undefined") return;

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const data = useStaticQuery(graphql`
    query MyLpDataQuery {
      allGoogleSheetLp {
        nodes {
          sports
          amecazi
          cleanLook
          denim
          normal
        }
      }
    }
  `);

  const columnKey = useMemo(() => {
    const columnMapping = {
      sports: "sports",
      amecazi: "amecazi",
      "clean look": "cleanLook",
      denim: "denim",
      normal: "normal",
    };

    return columnMapping[type] || null;
  }, [type]);

  const processedData = useMemo(() => {
    if (!columnKey || !data?.allGoogleSheetLp?.nodes) {
      return { title: type, data: [] };
    }

  const nodes = [...data.allGoogleSheetLp.nodes].sort((a, b) => {
    const orderA = parseInt(a.order || a.rowOrder || "0", 10);
    const orderB = parseInt(b.order || b.rowOrder || "0", 10);
    return orderA - orderB;
  });
  
  const result = [];

    const columnValues = nodes.map((node) => node[columnKey]).filter(Boolean);

    for (let i = 0; i < columnValues.length; i += 6) {
      if (i + 5 < columnValues.length) {
        const subtitle = columnValues[i];
        const articles = [
          columnValues[i + 1],
          columnValues[i + 2],
          columnValues[i + 3],
          columnValues[i + 4],
        ].filter(Boolean);

        const tag = columnValues[i + 5];

        if (subtitle && articles.length > 0) {
          result.push({
            subtitle,
            articles,
            tag: tag || "",
          });
        }
      }
    }

    return {
      title: type,
      data: result,
    };
  }, [data, columnKey, type]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [processedData]);

  const getSliderSettings = () => {
    return {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      prevArrow: <SamplePrevArrow />,
      nextArrow: <SampleNextArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 520,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
  };

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(100, 100, 100, 0.9)",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
          zIndex: 1,
          left: "-15px",
        }}
        onClick={onClick}
      >
        <MdOutlineKeyboardArrowLeft
          style={{ fontSize: "24px", color: "#fff" }}
        />
      </button>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(100, 100, 100, 0.9)",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
          zIndex: 1,
          right: "-17px",
        }}
        onClick={onClick}
      >
        <MdOutlineKeyboardArrowRight
          style={{ fontSize: "24px", color: "#fff" }}
        />
      </button>
    );
  }

  return (
    <div className="home-type" style={{ maxWidth: "1400px", margin: "auto" }}>
      {loading ? (
        <div
          className="loading-type"
          style={{ textAlign: "center", padding: "2em" }}
        >
          <div className="loading loading-card"></div>
          <p>Loading products...</p>
        </div>
      ) : processedData.data.length > 0 ? (
        processedData.data.map((section, sectionIndex) => (
          <div
            key={sectionIndex}
            className="home-type-section"
            style={{ marginBottom: "5em" }}
          >
            <h3
              className="home-type-subtitle u-text-center"
              style={{ fontSize: "28px" }}
            >
              {section.subtitle}
            </h3>
            {section.tag && (
              <a
                href={"/products/?q=" + encodeURIComponent(section.tag)}
                className="view-all-button"
                style={{
                  margin: "8px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "#333",
                  fontWeight: "bold",
                }}
              >
                View All{" "}
                <MdOutlineKeyboardArrowRight style={{ marginLeft: "4px" }} />
              </a>
            )}
            <div className="slider-buttons-homepage">
              <Slider {...getSliderSettings()}>
                {section.articles &&
                  section.articles.map((article, artIndex) => (
                    <div key={artIndex} style={{ padding: "0 10px" }}>
                      <ProductCardType search={article} />
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        ))
      ) : (
        ""
      )}
    </div>
  );
};

export default HomeTypeSection;
