import React, { useState, useContext, useEffect } from "react";
import Modal from "react-modal";
import { navigate, Link, useStaticQuery, graphql } from "gatsby";

import iconDelivery from "../images/icons/delivery.icon.svg";
import iconMessage from "../images/icons/message.icon.svg";
import _ from "underscore";
import {
  Item,
  List,
  Text,
  Tittle,
  TextArea,
  Buttons,
  customStyles,
  customStylesConfirmAddress,
} from "./styled/ShooppingStyled";

import { useQuery, useMutation } from "@apollo/client";
import {
  ADD_ORDER,
  CREATE_DELIVERY_STATUS,
  DELETE_SHIPPING_CART,
  GET_SHIPPING_CART,
} from "../mutations";
import {
  CURRENT_USER,
  GET_ADDRESS_BY_ID,
  GET_ADDRESSES,
  USER_DATA,
} from "../querys";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { CartContentContext } from "../context/CartContentContext";
import ModalAddress from "../components/Modal-address";

import {
  addDataToGoogleSheet,
  getImageFromDrive,
  sendOrderMail,
} from "../services/api";
import { AuthUserContext } from "../context/AuthUser";
Modal.setAppElement("#___gatsby");
const ShoppingCart = ({ current_user }) => {
  const {
    cartContent,
    setCartContent,
    setSwatchContent,
    // totalPrice,
    // setTotalPrice,
    shippingAddress,
    setShippingAddress,
    shippingTotalCost,
    setShippingTotalCost,
    totalAmout,
    setTotalAmout,
    setPaymentOrder,
  } = useContext(CartContentContext);

  /**
   * Variables for modal state
   */
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [modalPaymentMothod, setModalPaymentMothod] = useState(false);
  const [comunication, setComunication] = useState(false);
  //const [typePayment, setTypePayment] = useState("");
  const typePayment = "";

  const [totalPrice, setTotalPrice] = useState(0);

  const openModalComunication = () => {
    setComunication(true);
  };
  const closeModalComunication = () => {
    setComunication(false);
  };

  const closeModalPaymentMethod = () => {
    setModalPaymentMothod(false);
  };

  const closeModalConfirmation = () => {
    setModalConfirmation(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const queryAddress = useStaticQuery(graphql`
    query {
      allGoogleSheetShipping {
        nodes {
          area
          basicShippingCost
          ratio__byDecimalPointFirst_
          totalTextileCost____
        }
      }
    }
  `);

  const [addressSelected, setAddressSelected] = useState({});
  const [shipping, setShipping] = useState(0);

  const { authUser } = useContext(AuthUserContext);

  const [userInfo, setUserInfo] = useState({});

  const { data: current_u } = useQuery(CURRENT_USER);

  const { data: userData } = useQuery(USER_DATA, {
    variables: { id: authUser?.user?.id },
    onCompleted: (data) => {},
    onError: (error) => {
      // toast.error("User not found");
    },
  });

  useEffect(() => {
    setUserInfo(userData?.usersPermissionsUser?.data?.attributes);
    setPaymentOrder((prevState) => ({
      ...prevState,
      user: userData?.usersPermissionsUser?.data?.attributes,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, authUser]);
  const [idCart, setIdCart] = useState("");
  const [cartProducts, setCartProducts] = useState([]);
  const { data: cartList } = useQuery(GET_SHIPPING_CART, {
    variables: {
      id: authUser?.user?.id,
    },
    onCompleted: (data) => {
      setIdCart(data?.cartItems?.data[0]?.id);
      setCartProducts(
        _.groupBy(
          data?.cartItems?.data[0]?.attributes.cartitem,
          "typeofpurchase"
        )
      );

      // setCartProducts(data.cartItems.data[0]?.attributes?.cartitem);
    },
  });

  const dataQUERY = queryAddress.allGoogleSheetShipping.nodes;
  const handleCalculateCost = () => {
    if (Object.keys(addressSelected).length === 0) {
      setAddAnimation(false);
      closeModal(false);
      return toast.warning("You did not select any address");
    }
    // shipping rate calculation
    const filteredAddress = dataQUERY.filter(
      (query) => query.area === addressSelected.address
    );

    const filteredPrice = dataQUERY.filter((price) => {
      return parseInt(price.totalTextileCost____) <= parseInt(totalPrice);
    });

    let shippingCost;
    if (parseFloat(totalPrice) < 5) {
      shippingCost = 0;
      // shippingCost = 1 * parseInt(filteredAddress[0].basicShippingCost);
    } else {
      shippingCost = 0;
      // shippingCost =
      //   parseInt(filteredPrice[0].ratio__byDecimalPointFirst_) *
      //   parseInt(filteredAddress[0].basicShippingCost);
    }

    const dataShippingAddress = {
      shipFirstname: addressSelected.firstname,
      shipLastname: addressSelected.lastname,
      shipZipcode: addressSelected.zipcode,
      shipAddress: addressSelected.address,
      shipStreet: addressSelected.street,
      shipPhone: addressSelected.phone,
      shipCity: addressSelected.cityName,
    };

    if (current_user.confirmed) {
      setShipping(cartProducts?.length_order?.length !== 0 ? shippingCost : 0);
      setShippingTotalCost(
        cartProducts?.length_order?.length !== 0 ? shippingCost : 0
      );
      setTotalAmout(
        cartProducts?.length_order?.length !== 0
          ? shippingCost + totalPrice
          : 0 + totalPrice
      );
    } else {
      setShipping(shippingCost);
      setShippingTotalCost(shippingCost);
      setTotalAmout(shippingCost + totalPrice);
    }

    setShippingAddress(dataShippingAddress);
    closeModal(false);
  };
  const [addAnimation, setAddAnimation] = useState(false);
  const openModalPaymentMethod = () => {
    if (Object.keys(addressSelected).length !== 0) {
      setModalPaymentMothod(true);
    } else {
      setAddAnimation(true);
      toast.error("Select delivery address or add new delivery address");
    }
  };

  const handleChangeRadio = (data) => {
    setAddressSelected(data);
    setPaymentOrder((prevState) => ({ ...prevState, shippingAddress: data }));
  };

  const [getMainAddress, setGetMainAddress] = useState({});
  const [getSecondaryAddress, setGetSecondaryAddress] = useState([]);

  const { data: secondaryAddress } = useQuery(GET_ADDRESSES, {
    variables: {
      id: parseInt(authUser?.user?.id),
    },
    onCompleted: (data) => {
      setGetMainAddress(
        _.filter(
          data?.addresses?.data,
          (address) => address.attributes.main === true
        )
      );

      if (data?.addresses?.data?.length > 0) {
        const filteredAddress = dataQUERY.filter(
          (query) => query.area === data.addresses.data.address
        );
        const filteredPrice = dataQUERY.filter(
          (price) => price.totalTextileCost____ <= totalPrice
        );
        const dataAddressShipping = {
          shipFirstname: data.addresses.data.address.firstname,
          shipLastname: data.addresses.data.address.lastname,
          shipZipcode: data.addresses.data.address.zipcode,
          shipAddress: data.addresses.data.address.address,
          shipStreet: data.addresses.data.address.street,
          shipPhone: data.addresses.data.address.phone,
          shipCity: data.addresses.data.address.cityName,
        };
        setShippingAddress(dataAddressShipping);
        let shippingCost;
        if (totalPrice < 5) {
          shippingCost = 0;
          // shippingCost = 1 * parseInt(filteredAddress[0].basicShippingCost);
        } else {
          shippingCost = 0;
          // shippingCost =
          //   parseInt(filteredPrice[0].ratio__byDecimalPointFirst_) *
          //   parseInt(filteredAddress[0].basicShippingCost);
        }
        if (current_user.confirmed) {
          setShipping(cartContent.length !== 0 ? shippingCost : 0);
          setShippingTotalCost(cartContent.length !== 0 ? shippingCost : 0);
          setTotalAmout(
            parseFloat(
              (cartContent.length !== 0
                ? shippingCost + totalPrice
                : 0 + totalPrice
              ).toFixed(2)
            )
          );
        } else {
          setShipping(shippingCost);
          setShippingTotalCost(shippingCost);
          setTotalAmout(parseFloat((shippingCost + totalPrice).toFixed(2)));
        }
      } else {
        navigate("/edit-address");
        toast.error("You need add at least one shipping address.");
      }
    },
    onError: () => {},
  });

  useEffect(() => {
    setGetSecondaryAddress(secondaryAddress?.addresses?.data);
  }, [secondaryAddress, getMainAddress, getSecondaryAddress]);

  const [addOrder, { loading: loadAddOrder }] = useMutation(ADD_ORDER, {
    onCompleted: () => {},
    refetchQueries: ["orderUser"],
  });

  const [createDelivery] = useMutation(CREATE_DELIVERY_STATUS);

  var dataSEND;
  const uniqid = require("uniqid");
  const [dataDetails, setDataDetails] = useState([]);
  useEffect(() => {
    setPaymentOrder((prevState) => ({
      ...prevState,
      products: cartProducts?.length_order,
    }));

    setDataDetails({
      cart: [],
      swatch: cartProducts?.swatch?.map((swatch) => ({
        articleNumber: swatch.productId,
        colorNumber: swatch.colortype,
        quantity: parseFloat(swatch.quantity),
        totalPrice: parseFloat(swatch.price * swatch.quantity),
        regularPrice: parseFloat(swatch.price),
        image: swatch.image,
        supplierid: swatch?.supplierid,
        supplierproductname: swatch?.supplierproductname,
      })),
      shippingFee: shippingTotalCost,
      shipping: shippingAddress.shipAddress,
      cyty: shippingAddress.shipCity,
      username:
        shippingAddress.shipFirstname + " " + shippingAddress.shipLastname,
      phoneNumber: shippingAddress.shipPhone,
      zipcode: shippingAddress.shipZipcode,
      street: shippingAddress.shipStreet,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartContent, shippingAddress, shippingTotalCost]);

  const [pendingData, setPendingData] = useState(false);

  const getLocalNumericDate = () => {
    return new Intl.DateTimeFormat(navigator.language, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(new Date());
  };

  const handleConfirm = async () => {
    try {
      const mapArticleData = (item, type) => ({
        article: item.productId,
        color_number: item.colortype,
        quantity: parseInt(item.quantity),
        price_unit: item.unitprice,
        amount: type === "PRODUCT" ? totalLength : parseInt(totalSwatch),
        image: getImageThumbnail(item.image),
        type,
        supplierid: item.supplierid,
        supplierproductname: item.supplierproductname,
      });

      const getImageThumbnail = (imageUrl) => {
        const match = imageUrl.match(/[-\w]{25,}/);
        return match
          ? `https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${match[0]}`
          : "";
      };

      const dataArticle = {
        articles: cartProducts?.length_order?.map((item) =>
          mapArticleData(item, "PRODUCT")
        ),
        swatch: cartProducts?.swatch?.map((item) =>
          mapArticleData(item, "SWATCH")
        ),
      };

      const orderData = {
        variables: {
          data: {
            users_permissions_user: current_u?.me?.id,
            dateOrder: new Date(),
            amount: parseInt(totalAmout),
            username: `${userInfo?.firstName} ${userInfo?.lastName}`,
            companyName: userInfo?.companyName,
            status: "Ordered",
            phone: userInfo?.phoneNumber,
            email: userInfo?.email,
            payment_method: "invoice",
            harikae: userInfo?.harikae?.data?.attributes?.harikae,
            detailSwatch: dataDetails.swatch,
            shippingFee: parseInt(dataDetails?.shippingFee),
            shippingAddress: {
              address: dataDetails?.shipping,
              city: dataDetails?.cyty,
              shippingUser: dataDetails?.username,
              phoneNumber: dataDetails?.phoneNumber,
              zipcode: dataDetails?.zipcode,
              street: dataDetails?.street,
            },
          },
        },
        refetchQueries: ["orderUser"],
      };

      let orderResult;
      try {
        orderResult = await addOrder(orderData);
      } catch (orderError) {
        setPendingData(false);
        toast.error("Failed to process your order. Please try again.");
        return;
      }

      const orderDetails = {
        date_order: new Date().toLocaleString(),
        id_order: uniqid.time("COO-"),
        company_name: userInfo?.companyName,
        email: userInfo?.email,
        user_name: `${userInfo?.firstName} ${userInfo?.lastName}`,
        payment_method: typePayment,
        total_amount: totalAmout,
        total_price: totalPrice,
        shipping_fee: parseInt(dataDetails.shippingFee),
        article: dataArticle?.swatch,
        address_shipping: dataDetails.shipping,
        city_shipping: dataDetails.cyty,
        user_shipping: dataDetails.username,
        phone_number_shipping: dataDetails.phoneNumber,
        street_shipping: dataDetails.street,
        zip_code_shipping: dataDetails.zipcode,
        order_state: "Ordered",
        harikae: userInfo?.harikae?.data?.attributes?.harikae,
      };

      closeModalPaymentMethod();
      const token = localStorage.getItem("token");

      setPendingData(true);
      let googleSheetsSuccess = false;

      try {
        const sheetResponse = await addDataToGoogleSheet(token, orderDetails);
        if (sheetResponse.status === 200) {
          googleSheetsSuccess = true;
        } else {
          toast.warning("Order saved but there was an issue updating records");
        }
      } catch (sheetsError) {
        toast.warning("Order saved but there was an issue updating records");
      }

      let emailSuccess = false;

      let deliveryOrderSuccess = false;


      const getSimpleDateFormat = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Los meses en JS van de 0-11
        const day = String(now.getDate()).padStart(2, '0');
        
        return `${year}-${month}-${day}`; // Formato: "2025-02-28"
      };

      const deliveryData = {
        status: "Ordered",
        estimateDeliveryDate: getSimpleDateFormat() || "",
        carrier: "",
        trackingNumber: "",
        trackingUrl: "",
        description: `Hi ${userInfo?.firstName} ${userInfo?.lastName} Thank you for your order.`,
        orderhistory: orderResult?.data?.createOrderhistory?.data?.id,
      };

      try {
        const deliveryResponse = await createDelivery({
          variables: {
            data: deliveryData,
          },
          refetchQueries: ["deliveryStatus"],
        });

        if (deliveryResponse.status === 200) {
          deliveryOrderSuccess = true;
        }
      } catch (orderError) {
        setPendingData(false);
        toast.error("Failed to process your order. Please try again.");
        return;
      }

      try {
        const emailResponse = await sendOrderMail(token, {
          description: `Hi ${userInfo?.firstName} ${userInfo?.lastName} Thank you for your order.`,
          order: orderResult?.data?.createOrderhistory?.data?.id,
          status: "Ordered",
          expectedDeliveryDate: getLocalNumericDate(),
          company: userInfo?.companyName,
          username: userInfo?.firstName + " " + userInfo?.lastName,
          email: userInfo?.email,
          amount: totalAmout,
          detailSwatch: dataArticle?.swatch,
          shippingAddress: {
            street: dataDetails.street,
            city: dataDetails.cyty,
            address: dataDetails.shipping,
            zipcode: dataDetails.zipcode,
            phoneNumber: dataDetails.phoneNumber,
          },
        });

        if (emailResponse.status === "successful") {
          emailSuccess = true;
        } else {
          toast.warning(
            "Order was processed but confirmation email failed to send"
          );
        }
      } catch (emailError) {
        toast.warning(
          "Order was processed but confirmation email failed to send"
        );
      }

      resetOrderData();

      openModalComunication();
      handleDeleteItem();

      setTimeout(() => {
        navigate("/products");
        closeModalComunication();

        if (emailSuccess && googleSheetsSuccess && deliveryOrderSuccess) {
          toast.success("Your Order was successful!");
        } else if (emailSuccess) {
          toast.success("Your Order was successful!");
        } else {
          toast.success("Your Order was successful!");
        }
      }, 4000);
    } catch (error) {
      toast.error("An unexpected error occurred. Please contact support.");
      setPendingData(false);
    }
  };

  // Función auxiliar para resetear los datos después de una orden exitosa
  const resetOrderData = () => {
    setCartContent([]);
    setSwatchContent([]);
    setShippingAddress({});
    setTotalPrice(0);
    setShippingTotalCost(0);
    setTotalAmout(0);
    setPendingData(false);
  };

  const [deleteItem] = useMutation(DELETE_SHIPPING_CART);

  const handleDeleteItem = async () => {
    await deleteItem({
      variables: {
        id: idCart,
      },
      refetchQueries: ["getCartItems", "getCartItem"],
    });
  };

  const [modalAddress, setModalAddress] = useState(false);

  const [address, setAddress] = useState({
    lastname: "",
    firstname: "",
    companyName: "",
    address: "",
    zipcode: "",
    cityName: "",
    street: "",
    phone: "",
    user: current_user.id,
  });

  const handleModalAddress = () => {
    setAddress({
      lastname: "",
      firstname: "",
      companyName: "",
      address: "",
      zipcode: "",
      cityName: "",
      street: "",
      phone: "",
      user: current_user.id,
    });

    setModalAddress(true);
  };
  const [totalSwatch, setTotalSwatch] = useState(0);
  const [totalLength, setTotalLength] = useState(0);

  useEffect(() => {
    let tSwatch = 0;
    cartProducts?.swatch?.forEach((item) => {
      // tSwatch += item.price * item.quantity;
      tSwatch += 1 * parseFloat(item.quantity);
      setTotalSwatch(tSwatch);
    });
    let tLength = 0;
    cartProducts?.length_order?.forEach((item) => {
      tLength += parseFloat(item.price);
      setTotalLength(parseFloat(tLength).toFixed(2));
    });

    setTotalPrice(totalLength);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartProducts?.length_order, cartProducts?.swatch, userData]);

  const { data: dataAddress } = useQuery(GET_ADDRESS_BY_ID, {
    variables: { id: parseInt(current_user.id) },
  });
  const [addressUser, setAddressUser] = useState([]);
  useEffect(() => {
    setAddressUser(dataAddress?.addresses?.data);
  }, [dataAddress]);

  const handleConfirmPaymentCard = () => {
    setPaymentOrder((prevState) => ({
      ...prevState,
      totalPriceProducts: parseFloat(totalPrice).toFixed(2),
      shippingCost: parseFloat(shippingTotalCost).toFixed(2),
    }));
    navigate("/make-payment");
  };

  return (
    <>
      {(loadAddOrder || pendingData) && <Loading />}

      <div className="container" style={{ padding: "0 20px" }}>
        <h2 className="head-text-cart">Order Confirmation</h2>
        <div className="row u-margin-top-3">
          <div className="col-lg-8">
            {/* <Tittle>
              <img src={iconUser} alt="" /> Customer Information
            </Tittle>

            <List>
              <li>
                {getMainAddress[0]?.attributes?.firstname
                  ? getMainAddress[0]?.attributes.firstname
                  : ""}{" "}
                {getMainAddress[0]?.attributes?.lastname
                  ? getMainAddress[0]?.attributes?.lastname
                  : ""}{" "}
              </li>
              <li>
                {getMainAddress[0]?.attributes?.companyName
                  ? getMainAddress[0]?.attributes?.companyName
                  : ""}
              </li>
              <li>
                {getMainAddress[0]?.attributes?.address
                  ? getMainAddress[0]?.attributes?.address
                  : ""}{" "}
                {getMainAddress[0]?.attributes?.cityName
                  ? getMainAddress[0]?.attributes?.cityName
                  : ""}
              </li>
              <li>
                {getMainAddress[0]?.attributes?.state
                  ? getMainAddress[0]?.attributes?.state
                  : ""}{" "}
                {getMainAddress[0]?.attributes?.country
                  ? getMainAddress[0]?.attributes?.country
                  : ""}{" "}
                {getMainAddress[0]?.attributes?.zipcode
                  ? getMainAddress[0]?.attributes?.zipcode
                  : ""}{" "}
              </li>
              <li>
                {getMainAddress[0]?.attributes?.phone
                  ? getMainAddress[0]?.attributes?.phone
                  : ""}
              </li>
            </List> */}

            <Tittle>
              <img className="delivery-icon" src={iconDelivery} alt="" />
              Delivery
            </Tittle>
            <div className="text-address-responsive u-display-flex u-flex-direction-column">
              {Object.keys(addressSelected).length !== 0 ? (
                <List>
                  <>
                    <b>Address</b>
                    <li>
                      {addressSelected.firstname +
                        " " +
                        addressSelected.lastname}
                    </li>
                    <li>
                      {addressSelected.street + " "}{" "}
                      {addressSelected.aptNumber
                        ? addressSelected.aptNumber
                        : " "}
                      {" " +
                        addressSelected.address +
                        " " +
                        addressSelected.cityName}
                    </li>{" "}
                    <li>
                      {addressSelected.state &&
                        addressSelected.state + "" + addressSelected?.country &&
                        addressSelected.country}
                      {addressSelected.zipcode}
                    </li>
                    <li>{addressSelected.phone}</li>
                  </>
                </List>
              ) : (
                <></>
              )}
              {addressUser?.length === 0 ? (
                <button
                  onClick={() => handleModalAddress()}
                  className={`btn btn-dark u-size-btn ${
                    addAnimation && "animation-vibration"
                  }`}
                >
                  Add new delivery address
                </button>
              ) : (
                <button
                  onClick={openModal}
                  className={`btn-select-delivery btn btn-dark u-size-btn ${
                    addAnimation && "animation-vibration"
                  }`}
                >
                  Select delivery address
                </button>
              )}
            </div>
            {/* <ShippingMethod>
              <b>Shipping method</b>
              <Select name="" id="">
                <option value="fedex">International Shipping</option>
              </Select>
            </ShippingMethod> */}
            {/* <ul>
              {cartProducts.length !== 0 ? (
                <span className="text-length-order">Length Order</span>
              ) : (
                ""
              )}
              {cartProducts.map((item, i) => (
                <Item key={i}>
                  {item.image === "#N/A" ? (
                    <img className="image-default-small" src={noImage} alt="" />
                  ) : (
                    <img
                      className="image-product"
                      src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${
                        item.image.match(/[-\w]{25,}/)
                          ? item.image.match(/[-\w]{25,}/)[0]
                          : ""
                      }`}
                      alt="Product Image"
                    />
                  )}

                  <Text>
                    <span>{item.productId}</span>
                    <span>color type：{item.colortype}</span>
                    <p>
                      {item.quanty < 500 ? (
                        <span>$ {item.price} × 1 (m) × 1</span>
                      ) : (
                        
                        ""
                      )}                      
                      <span>Length(m):{item.quanty}</span>
                    </p>
                  </Text>
                </Item>
              ))}
              {cartProducts.length !== 0 ? (
                <span className="text-swatch-order">Swatch Order</span>
              ) : (
                ""
              )}
              {cartProducts.map((item, i) => (
                <Item key={i}>
                  {item.image === "#N/A" ? (
                    <img className="image-default-small" src={noImage} alt="" />
                  ) : (
                    <img
                      className="image-product"
                      src={`https://drive.google.com/thumbnail?authuser=0&sz=w150&id=${
                        item.image.match(/[-\w]{25,}/)
                          ? item.image.match(/[-\w]{25,}/)[0]
                          : ""
                      }`}
                      alt="Product Image"
                    />
                  )}

                  <Text>
                    <span>{item.productId}</span>
                    <span>color type：{item.colortype}</span>
                    <p>
               
                      <span>Swatches:{item.quantity}</span>
                    </p>
                  </Text>
                </Item>
              ))}
            </ul> */}
            <Tittle>
              <img src={iconMessage} alt="Message" />
              Inquiry Field
            </Tittle>
            <TextArea placeholder="If you have any inquiries, please enter here.(Up to 3000 characters)"></TextArea>

            <div className="card-pay-total widget-box">
              {(!authUser.typeCustomer === "Special customer" ||
                !authUser.typeCustomer === "Admin") && (
                <>
                  <div className="card-pay-total__row">
                    <span>Subtotal</span>
                    <span>$ {totalPrice}</span>
                  </div>
                  <div className="card-pay-total__row">
                    <span>Fee</span>
                    <span>$ 0.0</span>
                  </div>
                  <div className="card-pay-total__row">
                    <span>Shipping cost</span>
                    <span>$ {shippingTotalCost.toFixed(2)}</span>
                  </div>
                  <div className="card-pay-total__total">
                    <span>Total</span>
                    <span>
                      <b>
                        $
                        {parseFloat(
                          parseFloat(shippingTotalCost) + parseFloat(totalPrice)
                        ).toFixed(2)}
                      </b>
                      FOB JAPAN/mtr
                    </span>
                  </div>
                </>
              )}
              <button
                onClick={() => openModalPaymentMethod()}
                className="btn btn-primary btn-make-order u-text-uppercase"
              >
                Order
              </button>
              {/* {current_user.confirmed ? (
              ""
              ) : (
                <button
                  onClick={openModalConfirmation}
                  className="btn btn-primary u-text-uppercase"
                >
                  Order
                </button>
              )} */}

              {/* <p style={{ fontSize: "16px" }}>
                Once our staff receive this order information, we will send you
                an official confirmation. We will inform actual shipping
                quantity and total price by official confirmation due to random
                quantity of textile roll length.
              </p> */}
            </div>
          </div>
          <div className="col-lg-4 padding-cards-orderlists">
            <ul>
              {cartProducts?.length_order ? (
                // <span className="text-length-order">Length Order</span>
                <></>
              ) : (
                ""
              )}
              {cartProducts?.length_order?.map((item, i) => (
                <Item key={i}>
                  {item.image === "#N/A" ? (
                    // <img className="image-default-small" src={noImage} alt="" />
                    ""
                  ) : (
                    <img src={getImageFromDrive(item.image)} alt="" />
                  )}

                  <Text>
                    <span style={{ color: "black" }}>{item?.productId}</span>
                    {/* <span>color type：{item?.colornumberproductcategory}</span>
                    <p>
                      {item?.quanty < 500 ? (
                        <span>$ 1 × 1 (m) × 1</span>
                      ) : (
                        <span>$ {item?.priceB} × 1 (m) × 1</span>
                      )}                      
                      <span>Length(m):{item?.quantity}</span>
                    </p> */}
                  </Text>
                </Item>
              ))}
              {cartProducts?.swatch ? (
                // <span className="text-swatch-order">Swatch Order</span>
                <></>
              ) : (
                ""
              )}
              {cartProducts?.swatch?.map((item, i) => (
                <Item key={i}>
                  <div className="card-product-shoppingpage">
                    <small className="item-quantity-card">
                      {item.quantity}
                    </small>
                    {item.image === "#N/A" ? (
                      <img className="image-default-small" src={""} alt="" />
                    ) : (
                      <img src={getImageFromDrive(item.image)} alt="" />
                    )}
                  </div>

                  <Text>
                    <span>{item.productId}</span>
                    {/* <span>color type：{item.colortype}</span> */}
                    <p>
                      {/* {!current_user.confirmed && (
                        <>
                          <span>$ 1.00 x Swatch</span>
                          <span>
                            Subtotal:
                            {` $ 1`}
                          </span>
                        </>
                      )} */}
                      {/* <span>Swatches:{item.quantity}</span> */}
                    </p>
                  </Text>
                </Item>
              ))}
            </ul>
            <div className="u-text-center u-margin-tb-3">
              <Link className="btn btn-dark" to="/cart">
                Change or delete quantities
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modalIsOpen} style={customStyles}>
        <div className="head-modal">
          <p>Select Your Address</p>
          <button onClick={closeModal}>X</button>
        </div>
        <div className="content-card-radio">
          {getSecondaryAddress?.map((address, i) => (
            <div className="card-select-radio" key={i}>
              <input
                type="radio"
                id={address?.attributes.address + address.id}
                checked={
                  addressSelected.address === address?.attributes.address
                    ? true
                    : false
                }
                name="address"
                value={address?.attributes.address + address.id}
                className="selector-radio-address"
                onChange={(event) =>
                  handleChangeRadio(address.attributes, event)
                }
              />
              <label htmlFor={address?.attributes.address + address.id}>
                <span>
                  {address?.attributes?.firstname}{" "}
                  {address?.attributes?.lastname}
                </span>
                <span>
                  {address?.attributes?.street}{" "}
                  {address?.attributes?.aptNumber
                    ? address?.attributes?.aptNumber
                    : "  "}
                  <br />
                  {address?.attributes.address + " "}{" "}
                  {address?.attributes?.zipcode}
                </span>
                <span>{address?.attributes?.phone}</span>
              </label>
            </div>
          ))}
        </div>
        <Buttons>
          <button onClick={closeModal} className="btn btn-primary u-size-150">
            Cancel
          </button>
          <button
            onClick={() => handleCalculateCost()}
            className="btn btn-danger u-size-150"
          >
            OK
          </button>
          <button
            onClick={() => handleModalAddress()}
            className="btn btn-danger u-size-150"
          >
            Add new
          </button>
        </Buttons>
      </Modal>
      {/* MODAL FOR PAYMENT METHOD ORDER */}
      <Modal isOpen={modalConfirmation} style={customStylesConfirmAddress}>
        <div className="head-modal">
          <p>Are you sure of the delivery address?</p>
        </div>
        <table>
          <tbody>
            {Object.keys(addressSelected).length !== 0 ? (
              <>
                <tr>
                  <td>Name:</td>
                  <td>
                    {addressSelected?.firstname + " "}
                    {addressSelected?.lastname}
                  </td>
                </tr>
                <tr>
                  <td>Address:</td>
                  <td>
                    {addressSelected?.address + " "}
                    street {addressSelected?.street}
                  </td>
                </tr>
                <tr>
                  <td>Zip code</td>
                  <td>{addressSelected?.zipcode} </td>
                </tr>
                <tr>
                  <td>Phone Number:</td>
                  <td>{addressSelected?.phone}</td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td>Name:</td>
                  <td>
                    {JSON.stringify(addressSelected)}
                    {addressSelected?.firstname + " "}
                    {addressSelected?.lastname}
                  </td>
                </tr>
                <tr>
                  <td>Address:</td>
                  <td>
                    {addressSelected.address + " "}
                    street {addressSelected.street}
                  </td>
                </tr>
                <tr>
                  <td>Zip code</td>
                  <td>{addressSelected.zipcode} </td>
                </tr>
                <tr>
                  <td>Phone Number:</td>
                  <td>{addressSelected.phone}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
        <Buttons>
          <button
            onClick={closeModalConfirmation}
            className="btn btn-primary u-size-150"
          >
            Cancel
          </button>
          <Link to="/make-payment" className="btn btn-danger u-size-150">
            OK
          </Link>
        </Buttons>
      </Modal>
      {/* MODAL FOR SWATCH ORDER */}
      <Modal isOpen={modalPaymentMothod} style={customStylesConfirmAddress}>
        <div className="head-modal">
          <p>Are you sure of the delivery address?</p>
        </div>
        <table>
          <tbody>
            {Object.keys(addressSelected).length !== 0 ? (
              <>
                <tr>
                  <td>Name:</td>
                  <td>
                    {addressSelected.firstname + " "}
                    {addressSelected.lastname}
                  </td>
                </tr>
                <tr>
                  <td>Address:</td>
                  <td>
                  {addressSelected.street} {addressSelected.address + " "} {addressSelected.zipcode}
                  </td>
                </tr>
                {/* <tr>
                  <td>Zip code</td>
                  <td>{addressSelected.zipcode} </td>
                </tr> */}
                <tr>
                  <td>Phone Number:</td>
                  <td>{addressSelected.phone}</td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td>Name:</td>
                  <td>
                    {addressSelected?.data?.firstname + " "}
                    {addressSelected?.data?.lastname}
                  </td>
                </tr>
                <tr>
                  <td>Address:</td>
                  <td>
                    {addressSelected?.data?.address + " "}
                    street {addressSelected?.data?.street}
                  </td>
                </tr>
                <tr>
                  <td>Zip code</td>
                  <td>{addressSelected?.data?.zipcode} </td>
                </tr>
                <tr>
                  <td>Phone Number:</td>
                  <td>{addressSelected?.data?.phone}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>

        <Buttons>
          <button
            onClick={closeModalPaymentMethod}
            className="btn btn-primary u-size-150"
          >
            Cancel
          </button>

          {authUser.typeCustomer === "Admin" ||
          authUser.typeCustomer === "Special customer" ? (
            <button
              onClick={handleConfirm}
              className="btn btn-danger u-size-150"
            >
              OK
            </button>
          ) : (
            <button
              onClick={handleConfirmPaymentCard}
              className="btn btn-danger u-size-150"
            >
              OK
            </button>
          )}
        </Buttons>
      </Modal>

      <Modal isOpen={comunication} style={customStylesConfirmAddress}>
        <div className="container">
          <div className="row content-modal-check">
            <div className="main-container">
              <div className="check-container">
                <div className="check-background">
                  <svg
                    viewBox="0 0 65 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 25L27.3077 44L58.5 7"
                      stroke="white"
                      strokeWidth="13"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="check-shadow"></div>
              </div>
            </div>

            <h4>Thanks for ordering!</h4>
            <p>
              We'll contact you as soon as possible to coordinate the shipment of
              the order
            </p>
          </div>
        </div>
      </Modal>
      <ModalAddress
        typeModal={"add"}
        address={address}
        openModal={modalAddress}
        setAddress={setAddress}
        onCancel={() => setModalAddress(false)}
        setModalAddress={setModalAddress}
      />
    </>
  );
};

export default ShoppingCart;
