import React from "react";

import { Container } from "../components/styled/ProductsStyled";
import DataTable from "react-data-table-component";

import { customStylesTable } from "../services/modal";

import { getAllOrders } from "../querys";
import { useQuery } from "@apollo/client";
import { Link, navigate } from "gatsby";
import Loader from "../components/Loader";
import SmallLoader from "../components/small-loader";
import { formatDate } from "../utils/format";
const AllOrders = () => {
  const {
    data: allOrders,
    loading,
    error,
  } = useQuery(getAllOrders, {
    onCompleted: () => {},
  });
  
  const columns = [
    {
      name: "Order #",
      width: "100px",
      center: true,
      selector: (row) => <div className="cell-content">{row.id}</div>,
    },
    {
      name: "Actions",
      width: "80px",
      center: true,
      cell: (row) => (
        <Link
          to={"/orders-history/" + row.id}
          style={{
            color: "white",
            background: "black",
            padding: "2px 10px",
            fontSize: "12px",
          }}
        >
          Edit
        </Link>
      ),
    },
    {
      name: "Date Order",
      center: true,
      selector: (row) => (
        <div className="cell-content">{formatDate(row?.dateOrder)}</div>
      ),
    },
    {
      name: "Company Name",
      center: true,
      selector: (row) => <div className="cell-content">{row?.companyName}</div>,
    },
    {
      name: "Customer Name",
      center: true,
      selector: (row) => {
        const firstName =
          row?.users_permissions_user?.data?.attributes?.firstName || "";
        const lastName =
          row?.users_permissions_user?.data?.attributes?.lastName || "";
        return (
          <div className="cell-content">
            {`${firstName} ${lastName}`.trim()}
          </div>
        );
      },
    },
    {
      name: "Email",
      center: true,
      selector: (row) => <div className="cell-content">{row?.email}</div>,
    },
    {
      name: "Status",
      center: true,
      selector: (row) => {
        const status = row?.delivery_status?.data?.attributes?.status || "";
        const statusColors = {
          Ordered: "order-status",
          "Shipped (from Japan)": "shipped-status",
          "Shipped (arrived in your country)": "shipped-status",
          Arrived: "arrived-status",
        };
        const color = statusColors[status] || "default";
        return <div className={color}>{status}</div>;
      },
    },
    {
      name: "Items",
      center: true,
      cell: (row) => {
        const products = row?.detailProducts?.length || 0;
        const swatch = row?.detailSwatch?.length || 0;
        const totalItems = products + swatch;

        return <div className="cell-content">{totalItems} items</div>;
      },
    },
  ];

  const handleRowClick = (row) => {
    navigate(`/orders-history/${row.id}`);
  };

  if (error)
    return (
      <div
        style={{
          display: "flex",
          marginTop: "5em",
          marginBottom: "5em",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          opacity: "0.6",
        }}
      >
        <svg
          style={{ width: "100px", height: "100px" }}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M2.20164 18.4695L10.1643 4.00506C10.9021 2.66498 13.0979 2.66498 13.8357 4.00506L21.7984 18.4695C22.4443 19.6428 21.4598 21 19.9627 21H4.0373C2.54022 21 1.55571 19.6428 2.20164 18.4695Z"
              stroke="#dedede"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>{" "}
            <path
              d="M12 9V13"
              stroke="#dedede"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>{" "}
            <path
              d="M12 17.0195V17"
              stroke="#dedede"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>{" "}
          </g>
        </svg>
        <p style={{ marginTop: "1em" }}>
          You don’t have access to this section.
        </p>
      </div>
    );

  return (
    <>
      <Container className="container">
        <div className="">
          <div className="table_style">
            <h2 style={{ margin: "1em 0" }}>Order History</h2>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: "0.6",
                }}
              >
                <div
                  className="loading loading-card"
                  style={{
                    width: "50px",
                    height: "50px",
                    margin: "3em auto",
                    marginBottom: "2em",
                  }}
                ></div>
                <p>Loading records, please wait...</p>
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={allOrders?.orderhistories?.data.map((item) => ({
                  id: item.id,
                  ...item.attributes,
                }))}
                onRowClicked={handleRowClick}
                customStyles={customStylesTable}
                pagination
                paginationPerPage={50}
                noDataComponent={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      opacity: "0.6",
                    }}
                  >
                    <div
                      className="loading loading-card"
                      style={{
                        width: "50px",
                        height: "50px",
                        margin: "3em auto",
                        marginBottom: "2em",
                      }}
                    ></div>
                    <p>Loading records, please wait...</p>
                  </div>
                }
              />
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default AllOrders;
