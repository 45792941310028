import React from "react";
import { Product, Quantity } from "./styled/CartStyled";
import { getImageFromDrive } from "../services/api";
import { Link } from "gatsby";
import { cleanUrl } from "../models/filter";
import { BsInfoCircleFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";

const ItemCart = ({
  item,
  type,
  pending,
  handleDeleteItem,
  handleAddItems,
  handleLessItems,
}) => {
  return (
    <div className="card-item-product">
      <Product>
        {!item.image ||
        item.image === undefined ||
        item.image === "#N/A" ||
        item.image === "-" ? (
          // <img className="image-default-small" src={noImage} alt="" />
          ""
        ) : (
          <img
            className="image-product"
            alt={item.productId}
            src={getImageFromDrive(item.image)}
          />
        )}
        <div className="cart-items-text">
          <table>
            <tbody>
              <tr>
                <td className="cart-item-article">
                  <Link
                    to={`/products/${cleanUrl(item.productId)}`}
                    className="cart-items-text_link"
                  >
                    <b>{item.productId}</b>
                  </Link>
                  <div className="tooltip-summery-info">
                    <BsInfoCircleFill />
                    <span>{item.productSummery}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Color type:</td>
                <td>
                  <b>{item.colortype}</b>
                </td>
              </tr>
              <tr>
                <td>Price {type === "swatch" ? "" : "(Meter)"} :</td>
                <td>
                  <b>$ 1 × {type === "swatch" ? "Swatch" : "1 (m)"}</b>
                </td>
              </tr>
              <tr>
                <td>Quantity:</td>
                <td>
                  <b>{item.quantity}</b>
                </td>
              </tr>
              {type === "swatch" ? (
                ""
              ) : (
                <tr className="tag-sub-total">
                  <td>Sub Total:</td>

                  <td>
                    <b>
                      ${" "}
                      {item.typeofpurchase === "swatch"
                        ? item.quantity
                        : parseFloat(item.price).toFixed(2)}
                    </b>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Product>

      <div className="count-items u-display-flex">
        <Quantity>
          <div>
            <button disabled={pending} onClick={handleLessItems}>
              <p className="btn-quantity-minus">-</p>
            </button>
            <span>{item.quantity}</span>
            <button onClick={handleAddItems} disabled={pending}>
              +
            </button>
          </div>
        </Quantity>
        <button
          className="btn-delete-item"
          disabled={pending}
          onClick={handleDeleteItem}
          //   onClick={() => handleDeleteProduct(item.id, item.totalPrice)}
        >
          <IoMdClose />
          <p className="btn-remove-text">Remove</p>
        </button>
      </div>
    </div>
  );
};

export default ItemCart;
